import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

const DanaGalleryPage = () => {
  return (
    <Layout>
      <h1 className="mobile-header lg:desktop-header">Dana's Art Gallery</h1>
      <div className="flex flex-col gap-4 mt-4">
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_7194.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6541.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_7286.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6090.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_7193.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6539.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6062.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6260.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6426.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/dana-gallery/med-buda.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_9041.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_1308.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6647.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6264.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6268.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_7044.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6545.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6532.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6536.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_1332.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_9924.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/casa-asada.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/cat.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/citrus.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_1318.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_6648.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_7418.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_1326.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_8035.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/dana-gallery/IMG_8061.png"
              alt="Dana's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DanaGalleryPage
